import { BackTop, Layout } from 'antd';
import React from 'react';
import NavBar from 'ui/navBar';
import styles from './index.module.scss';

const { Content, Footer } = Layout;

interface Props {
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  isConnected: boolean;
}

function Page({ children = null, footer = null, img, isConnected }: Props) {
  return (
    <Layout className={styles.layout}>
      <NavBar
        isConnected={isConnected}
        logoSections={
          [
            // Custom logo sections can be added here, ex:
            // Should be an array of sections with icons: https://ant.design/components/icon/
            // example:
            // [{
            //   label: 'Home',
            //   icon: HomeOutlined,
            //   onClick: () => {},
            // }]
          ]
        }
      />
      {img ? <img src={img.src} alt={img.alt} /> : null}

      <Content className={styles.content}>
        {children}
        <BackTop />
      </Content>

      {footer ? <Footer className={styles.footer}>{footer}</Footer> : null}
      {process.env.REACT_APP_DISPLAY_VERSION === 'true' && (
        <div
          style={{
            right: 0,
            bottom: 0,
            left: 0,
            float: 'right',
            padding: 3,
            textAlign: 'right',
            fontSize: '12px',
          }}
        >
          v2.3.2
        </div>
      )}
    </Layout>
  );
}

export default Page;
